.selectize-control.plugin-remove_button [data-value] .remove {
  @apply text-sm text-center text-gray-700 font-semibold ml-2 px-2;
}

.selectize-control.plugin-remove_button [data-value] .remove:hover {
  background: rgb(0 0 0 / 15%);
}

.selectize-control.plugin-remove_button .remove-single {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 23px;
}

.selectize-control {
  @apply leading-tight;

  position: relative;
}

.modal .selectize-control {
  position: unset;
}

.selectize-control.single {
  line-height: 23px;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
  @apply text-gray text-base;
}

.selectize-input,
.selectize-control.single .selectize-input.input-active {
  cursor: text;
  display: inline-block;
}

.selectize-input {
  @apply border-b border-gray-800 px-3 py-2;

  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.field-with-errors .selectize-input {
  @apply border-red;
}

.selectize-input.focus {
  @apply border-teal bg-gray-100;
}

.selectize-input > * {
  vertical-align: baseline;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
}

.selectize-control.multi .selectize-input > div {
  @apply cursor-pointer bg-teal text-white text-sm tracking-wide rounded pl-3 py-1 my-1 mr-1;
}

.selectize-control.multi.drug-class .selectize-input > div {
  @apply text-gray-700;

  background: rgb(58 55 125 / 30%);
}

.selectize-control.multi.drug .selectize-input > div {
  @apply text-gray-700;

  background: rgb(159 91 148 / 30%);
}

.selectize-input > input {
  display: inline-block !important;
  padding: 0 !important;
  min-height: 0 !important;
  max-height: none !important;
  max-width: 100% !important;
  margin: 0 2px 0 0 !important;
  text-indent: 0 !important;
  border: 0 none !important;
  background: none !important;
  line-height: inherit !important;
  user-select: auto !important;
  box-shadow: none !important;
}

.selectize-input > input:focus {
  @apply outline-none;
}

.selectize-input::after {
  content: ' ';
  display: block;
  clear: left;
}

.selectize-input.dropdown-active::before {
  content: ' ';
  display: block;
  position: absolute;
  background: #f0f0f0;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.field-with-errors .selectize-input input {
  @apply text-red;
}

.selectize-dropdown {
  @apply bg-white border border-t-0 border-gray-200 rounded-b-sm shadow cursor-pointer absolute z-10;
}

.selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}

.selectize-dropdown .option,
.selectize-dropdown .optgroup-header {
  @apply px-4 py-3;
}

.selectize-dropdown .active {
  @apply bg-teal-25 text-teal;
}

.selectize-dropdown .active.create {
  @apply text-teal;
}

.selectize-dropdown .create {
  @apply text-gray-400 px-4 py-3;
}

.selectize-dropdown-content {
  overflow-y: auto;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  overflow-x: hidden;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
  cursor: pointer;
}

.selectize-control.single .selectize-input.input-active,
.selectize-control.single .selectize-input.input-active input {
  cursor: text;
}

.selectize-control.single .selectize-input::after,
.has-selectize-caret::after {
  content: ' ';
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #808080 transparent transparent;
}

.selectize-control.single .selectize-input.dropdown-active::after {
  margin-top: -4px;
  border-width: 0 5px 5px;
  border-color: transparent transparent #808080;
}

.selectize-input .item[data-value=''] {
  @apply text-gray-300;
}
