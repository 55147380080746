@keyframes icon-animation {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  20%,
  60% {
    opacity: 1;
    transform: scale(1.75);
    color: theme("colors.green.default");
  }

  100% {
    opacity: 1;
    transform: scale(1);
    color: theme("colors.gray.default");
  }
}

@keyframes text-animation {
  0% {
    transform: translateX(-2rem);
    color: inherit;
  }

  20%,
  60% {
    transform: translateX(0.625rem);
    color: theme("colors.green.default");
  }

  100% {
    transform: translateX(0);
    color: theme("colors.gray.default");
  }
}

@keyframes side-to-side {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(200%);
  }
}

.status-icon {
  animation: 1.5s ease-out icon-animation;
  animation-fill-mode: forwards;
}

.status-text {
  animation: 1.5s ease text-animation;
  animation-fill-mode: forwards;
}

.rotate-right {
  transform: rotate(-90deg);
}

.animate-slow {
  transition: 0.3s;
  transition-property: transform, transform-origin, opacity, color, border-color, background-color, outline-color, perspective, perspective-origin, box-shadow, text-shadow;
}

.transition-smooth {
  transition:
    background-color 0.5s ease,
    color 0.5s ease,
    box-shadow 0.5s ease;
}

.disappear {
  opacity: 0;
  transition: all 500ms;
}

.turbolinks-progress-bar {
  @apply bg-teal;
}

.pulse {
  @apply shadow-pulse rounded !important;
}

.highlight-ring {
  @apply border-3 border-indigo-300 bg-indigo-50 rounded-lg;
}

.rotate-when-active {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
}

.active .rotate-when-active {
  transform: rotate(45deg);
}

.animate-side-to-side {
  width: 50%;
  animation: 3s ease-in-out side-to-side;
  animation-iteration-count: infinite;
}

.shadow-when-overflowing {
  /* Taken from https://stackoverflow.com/questions/44793453/how-do-i-add-a-top-and-bottom-shadow-while-scrolling-but-only-when-needed */
  background: /* Shadow covers */
    linear-gradient(white 30%, rgb(255 255 255 / 0%)),
    linear-gradient(rgb(255 255 255 / 0%), white 70%) 0 100%,
    radial-gradient(50% 0, farthest-side, rgb(0 0 0 / 30%), rgb(0 0 0 / 0%)),
    radial-gradient(50% 100%, farthest-side, rgb(0 0 0 / 30%), rgb(0 0 0 / 0%)) 0 100%;
  background: /* Shadow covers */
    linear-gradient(white 30%, rgb(255 255 255 / 0%)),
    linear-gradient(rgb(255 255 255 / 0%), white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgb(0 0 0 / 30%), rgb(0 0 0 / 0%)),
    radial-gradient(farthest-side at 50% 100%, rgb(0 0 0 / 30%), rgb(0 0 0 / 0%)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}
