input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.algolia-autocomplete {
  @apply w-full;
}

.aa-input-container-sm {
  width: 100px;
}

.aa-InputWrapperPrefix {
  display: none;
}

.aa-DetachedFormContainer {
  width: 75%;
}

.aa-Form {
  @apply px-4 py-3 appearance-none w-full;

  outline: 0;
  height: 1em;
  padding: 0;
  margin: 0;
  border: none;
}

.aa-Input {
  padding-left: 5px;
}

.aa-Form:focus-within {
  border: none;
  box-shadow: none;
}

.aa-Panel {
  width: 700px !important;
  top: unset !important;
  left: unset !important;
  z-index: 500;
}

.aa-input-container-md {
  width: 150px;
}

.aa-input-search-sm {
  @apply px-4 py-3 appearance-none;

  width: 70px;
}

.aa-input-search-md {
  @apply px-4 py-3 appearance-none;

  width: 150px;
}

.aa-input-search::-webkit-search-decoration,
.aa-input-search::-webkit-search-cancel-button,
.aa-input-search::-webkit-search-results-button,
.aa-input-search::-webkit-search-results-decoration {
  display: none;
}

.aa-input-search-md:focus {
  outline: 0;
  width: 300px;

  @apply border-teal;
}

.aa-input-search-sm:focus {
  outline: 0;

  @apply border-teal;
}

.aa-hint {
  color: #e4e4e4;
}

.aa-small-panel {
  @apply w-full;

  position: absolute;
  left: 0;
  top: 40px;
}

.aa-medium-panel {
  margin-left: -150px;
  margin-top: 40px;
}

.aa-dropdown-menu {
  margin-top: 2px;
  max-height: 700px;

  @apply pb-1 bg-white shadow-md border border-gray-200 rounded-b overflow-auto;
}

.aa-suggestion {
  padding: 12px;
  cursor: pointer;
  transition: 0.2s;
  display: -webkit-box;
  display: flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.aa-suggestion.aa-cursor {
  background-color: rgb(84 181 198 / 10%);
}

.aa-suggestion em {
  color: #54b5c6;
  background-color: rgb(84 181 198 / 10%);
  font-style: normal;
}

.campaign-search-popup {
  @apply fixed z-dialog px-4 pt-24 flex items-start justify-center inset-0;
}

.campaign-search-overlay {
  @apply absolute inset-0 bg-gray-800 opacity-50;
}

.campaign-search-modal {
  @apply relative bg-indigo-500 rounded-lg overflow-hidden shadow-lg max-w-lg w-full;
}

.campaign-search-form {
  @apply flex justify-between;
}

.campaign-search-input {
  @apply appearance-none bg-transparent border-0 py-4 px-6 text-lg text-white outline-none flex-grow;
}

.campaign-search-reset {
  @apply mr-2 text-white text-3xl leading-0 outline-none p-2;
}

.campaign-search-reset:focus {
  @apply outline-none;
}

.campaign-search-results-container {
  @apply overflow-y-auto max-h-500;
}

.campaign-search-results {
  @apply grid grid-cols-1 bg-white;
}

.campaign-search-result {
  @apply text-xs w-auto p-0 m-0;
}

.campaign-search-result mark {
  @apply bg-teal-100;
}

.campaign-search-results-empty {
  @apply text-center text-gray-300 py-6;
}

.algolia-pagination {
  @apply bg-gray-100 border-t;
}

.algolia-pagination-list {
  @apply flex text-base justify-center py-2;
}

.algolia-pagination-item {
  @apply inline-flex items-center py-1 text-center border-b-2 border-transparent;
}

.algolia-pagination-item:hover {
  @apply bg-teal-25 text-teal;
}

.algolia-pagination-item a {
  @apply px-2;
}

.algolia-pagination-item a:focus {
  @apply outline-none shadow-outline;
}

.algolia-pagination-item-selected {
  @apply bg-teal-50 border-teal text-teal font-bold;
}

.ais-Hits-item {
  overflow: hidden;
}
