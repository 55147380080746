.pika-single {
  @apply rounded shadow-md mt-1 font-sans;
}

.pika-button {
  @apply bg-white;
}

.pika-button:hover {
  @apply bg-teal-25 text-teal;
}

.is-today .pika-button {
  @apply text-gray-700;
}

.is-selected .pika-button {
  @apply bg-teal shadow-none text-white;
}
