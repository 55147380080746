.pagination {
  @apply flex justify-between items-center my-2 text-sm;
}

.pages {
  @apply select-none;
}

.pagination b {
  @apply font-semibold;
}

.page {
  @apply inline-flex items-center py-1 text-center border-b-2 border-transparent;
}

.page a {
  @apply px-2;
}

.page:hover {
  @apply bg-teal-25 text-teal;
}

.page.current {
  @apply bg-teal-50 px-2 border-teal text-teal font-bold;
}

.page.disabled {
  @apply px-2 text-gray-300 cursor-not-allowed;
}

.page.disabled:hover {
  @apply bg-transparent;
}

.page.prev {
  @apply w-auto mr-1;
}

.page.next {
  @apply w-auto ml-1;
}
