trix-toolbar [data-trix-button-group='file-tools'] {
  display: none;
}

trix-toolbar:not(.trix-annotations) [data-trix-attribute='strike'],
trix-toolbar [data-trix-attribute='heading1'] {
  display: none;
}

trix-toolbar,
.like-trix-toolbar {
  @apply sticky top-0 bg-white pt-2;

  height: 50px;
  max-height: 50px;
}

.space-for-trix-toolbar {
  margin-top: calc(0.5rem + 50px);
}

.side-by-side.space-for-trix-toolbar {
  /* .side-by-side > .trix-content { includes 0.4rem of padding-top not mirrored in the trix-editor. }  */
  margin-top: calc(0.1rem + 50px);
}

trix-toolbar .trix-button--icon-underline::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z'/%3E%3C/svg%3E");
}

.trix-button--icon-align-left::before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-text-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/></svg>');
}

.trix-button--icon-align-center::before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-text-center" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/></svg>');
}

.trix-button--icon-align-right::before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-text-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/></svg>');
}

.trix-button--icon-highlight::before {
  background-image: url("../../assets/images/icons/highlighter.svg");
}

.trix-button--icon-reset::before {
  background-image: url("../../assets/images/icons/trashcan.svg");
}

.trix-button--icon-quote,
.trix-button--icon-code {
  display: none !important;
}

trix-editor,
.trix-content {
  @apply break-words;

  overflow-wrap: anywhere;
}

trix-editor a,
.trix-content a {
  @apply text-teal underline;
}

trix-editor ol {
  @apply ml-8 my-2 list-decimal;
}

trix-editor ol ol {
  list-style-type: lower-latin;
}

trix-editor ol ol ol {
  list-style-type: lower-roman;
}

trix-editor ol ol ol ol {
  @apply list-decimal;
}

trix-editor ol ol ol ol ol {
  list-style-type: lower-latin;
}

trix-editor ol ol ol ol ol ol {
  list-style-type: lower-roman;
}

trix-editor ol ol ol ol ol ol ol {
  @apply list-decimal;
}

trix-editor ol ol ol ol ol ol ol ol {
  list-style-type: lower-latin;
}

trix-editor ol ol ol ol ol ol ol ol ol {
  list-style-type: lower-roman;
}

trix-editor mark::selection,
trix-editor mark ::selection {
  background-color: color-mix(in srgb, highlight 55%, #fee17a 45%);
  color: white;
}

trix-editor del,
.trix-content del {
  /* Update known_outlook_incompatibilities_with_equivalents in trix_helper.rb when changing this */
  @apply text-red;
}

.trix-content ol {
  @apply ml-8 my-2 list-decimal;
}

.trix-content ol ol {
  list-style-type: lower-latin;
}

.trix-content ol ol ol {
  list-style-type: lower-roman;
}

.trix-content ol ol ol ol {
  @apply list-decimal;
}

.trix-content ol ol ol ol ol {
  list-style-type: lower-latin;
}

.trix-content ol ol ol ol ol ol {
  list-style-type: lower-roman;
}

.trix-content ol ol ol ol ol ol ol {
  @apply list-decimal;
}

.trix-content ol ol ol ol ol ol ol ol {
  list-style-type: lower-latin;
}

.trix-content ol ol ol ol ol ol ol ol ol {
  list-style-type: lower-roman;
}

trix-editor ul {
  @apply ml-8 list-disc;
}

trix-editor ul ul {
  list-style-type: circle;
}

trix-editor ul ul ul {
  list-style-type: square;
}

trix-editor ul ul ul ul {
  @apply list-disc;
}

trix-editor ul ul ul ul ul {
  list-style-type: circle;
}

trix-editor ul ul ul ul ul ul {
  list-style-type: square;
}

trix-editor ul ul ul ul ul ul ul {
  @apply list-disc;
}

trix-editor ul ul ul ul ul ul ul ul {
  list-style-type: circle;
}

trix-editor ul ul ul ul ul ul ul ul ul {
  list-style-type: square;
}

.trix-content ul {
  @apply ml-8 list-disc;
}

.trix-content ul ul {
  list-style-type: circle;
}

.trix-content ul ul ul {
  list-style-type: square;
}

.trix-content ul ul ul ul {
  @apply list-disc;
}

.trix-content ul ul ul ul ul {
  list-style-type: circle;
}

.trix-content ul ul ul ul ul ul {
  list-style-type: square;
}

.trix-content ul ul ul ul ul ul ul {
  @apply list-disc;
}

.trix-content ul ul ul ul ul ul ul ul {
  list-style-type: circle;
}

.trix-content ul ul ul ul ul ul ul ul ul {
  list-style-type: square;
}

trix-editor:not(.side-by-side) {
  @apply max-h-96 overflow-y-auto;
}

.side-by-side-container {
  @apply border border-gray-200 rounded bg-gray-100;
}

.side-by-side > .trix-content {
  padding: 0.4em 0.6em;
  line-height: 1.15;
  min-height: 5em;
}

.side-by-side-header {
  @apply border-b border-gray-200 bg-gray-100 font-semibold flex items-center justify-center;

  height: 50px;
  max-height: 50px;
}

.side-by-side ol li,
.side-by-side ul li {
  @apply mx-0;
}

.trix-content h1 {
  @apply text-xl font-bold mb-4;
}

trix-editor[disabled],
trix-toolbar[disabled] {
  pointer-events: none;
}

.htmldiff-output ins {
  text-decoration: none;
  background-color: #d4fcbc;
}

.htmldiff-output del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}

.htmldiff-only-removals .htmldiff-output ins,
.htmldiff-only-additions .htmldiff-output del {
  display: none;
}
