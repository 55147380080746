.break-word {
  word-break: break-word;
  overflow-wrap: break-word;
}

.popover-generic {
  @apply bg-white shadow-lg z-50 p-4 rounded;
}

[data-popper-arrow],
[data-popper-arrow]::after {
  width: 10px;
  height: 10px;
}

[data-popper-arrow]::after {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  background-color: white;
}

.popover[data-popper-placement='top'] [data-popper-arrow] {
  bottom: -5px;
}

.popover[data-popper-placement='bottom'] [data-popper-arrow] {
  top: -5px;
}

.popover[data-popper-placement='left'] [data-popper-arrow] {
  right: -5px;
}

.popover[data-popper-placement='right'] [data-popper-arrow] {
  left: -5px;
}

.sticky-footer-1 {
  display: grid;
  grid-template-rows: auto;
  min-height: 100%;
}

.sticky-footer-2 {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;
}

.sticky-footer-3 {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
}

.sortable {
  cursor: pointer;
}

.sortable::after {
  position: absolute;
  cursor: pointer;
  content: "";
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/images/icons/sortable.svg');
}

.sortable.asc::after {
  background-image: url('../../assets/images/icons/sort-arrow-up.svg');
}

.sortable.desc::after {
  background-image: url('../../assets/images/icons/sort-arrow-down.svg');
}

.sortable.text-white::after {
  background-image: url('../../assets/images/icons/sortable-white.svg');
}

.sortable.text-white.asc::after {
  background-image: url('../../assets/images/icons/sort-arrow-up-white.svg');
}

.sortable.text-white.desc::after {
  background-image: url('../../assets/images/icons/sort-arrow-down-white.svg');
}

@responsive {
  /* Tailwind has 1-6 classes but not any higher. The largest drug count of any deliverable is 12. */
  .row-span-7 {
    grid-row: span 7 / span 7;
  }

  .row-span-8 {
    grid-row: span 8 / span 8;
  }

  .row-span-9 {
    grid-row: span 9 / span 9;
  }

  .row-span-10 {
    grid-row: span 10 / span 10;
  }

  .row-span-11 {
    grid-row: span 11 / span 11;
  }

  .row-span-12 {
    grid-row: span 12 / span 12;
  }
}
