.bulk-action-bar {
  @apply sticky top-0 px-4 bg-indigo-500 text-white border-b border-indigo-200 flex justify-between items-center;
}

.bulk-counter {
  @apply font-semibold;
}

.bulk-actions {
  @apply flex text-sm tracking-sm items-center;
}

.btn-action {
  @apply rounded py-2 px-3 mx-2 cursor-pointer flex items-center outline-none select-none;
}

.btn-action:hover {
  @apply bg-indigo-200 text-indigo-600;
}

.btn-action:focus {
  @apply outline-none shadow-outline;
}

.bulk-dropdown {
  @apply absolute bg-white mt-12 shadow-lg top-0 right-0 rounded w-300 mr-6 overflow-hidden;
}

.bulk-dropdown-action {
  @apply text-gray-800 py-2 px-4 text-sm cursor-pointer flex items-center border-b select-none;
}

.bulk-dropdown-action:last-child {
  @apply border-b-0;
}

.bulk-dropdown-action:hover {
  @apply bg-teal-50;
}

.bulk-dropdown-title {
  @apply text-sm text-gray-600 text-left px-4 py-2 bg-gray-100 border-b;
}

.bulk-actions-spinner {
  @apply h-4 w-4;

  font-size: 10px;
  text-indent: -9999em;
  border-radius: 50%;
  background: #fff;
  background: linear-gradient(to left, #fff 10%, rgb(255 255 255 / 0%) 42%);
  background: linear-gradient(to left, #fff 10%, rgb(255 255 255 / 0%) 42%);
  background: linear-gradient(to left, #fff 10%, rgb(255 255 255 / 0%) 42%);
  background: linear-gradient(to left, #fff 10%, rgb(255 255 255 / 0%) 42%);
  background: linear-gradient(to right, #fff 10%, rgb(255 255 255 / 0%) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}

.bulk-actions-spinner::before {
  width: 50%;
  height: 50%;
  background: #fff;
  border-radius: 100% 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.bulk-actions-spinner::after {
  background: #726bbd;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  inset: 0;
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
