.badge {
  @apply inline-flex items-center px-3 py-0.5 rounded-full text-sm text-center font-semibold font-sans leading-5 border border-transparent;
}

.badge-gray-dark {
  @apply badge;

  color: #f7fafc;
  background-color: #718096;
}

.badge-gray {
  @apply badge border-gray-200;

  color: #252f3f;
  background-color: #f4f5f7;
}

.badge-red {
  @apply badge;

  color: #9b1c1c;
  background-color: #fde8e8;
}

.badge-orange {
  @apply badge;

  color: #8a2c0d;
  background-color: #feecdc;
}

.badge-yellow {
  @apply badge;

  color: #723b13;
  background-color: #fdf6b2;
}

.badge-green {
  @apply badge;

  color: #03543f;
  background-color: #def7ec;
}

.badge-teal {
  @apply badge;

  color: #05505c;
  background-color: #d5f5f6;
}

.badge-blue {
  @apply badge;

  color: #1e429f;
  background-color: #e1effe;
}

.badge-indigo {
  @apply badge;

  color: #42389d;
  background-color: #e5edff;
}

.badge-purple {
  @apply badge;

  color: #5521b5;
  background-color: #edebfe;
}

.badge-pink {
  @apply badge;

  color: #99154b;
  background-color: #fce8f3;
}

.badge-sm {
  @apply text-xs leading-4 px-2.5;
}

.badge-xs {
  @apply text-xs px-2.5 py-0 h-5;
}

.diff-original {
  @apply badge-gray badge-sm max-w-sm rounded-xl;
}

.diff-remove {
  @apply badge-red badge-sm max-w-sm rounded-xl;
}

.diff-add {
  @apply badge-green badge-sm max-w-sm rounded-xl;
}

.diff-change {
  @apply badge-yellow badge-sm max-w-sm rounded-xl;
}

.diff-remove *,
.diff-add * {
  @apply truncate;
}
