h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-sans;
}

.portal-background {
  background-image: url("../../assets/images/portal-background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.file-border {
  @apply border-b;
}

.file-border:first-child {
  @apply border-t border-b;
}

.file-border:last-child {
  @apply border-b-0;
}

@screen xs {
  .file-border {
    @apply border-l;
  }

  .file-border:first-child {
    @apply rounded-tl border-l;
  }

  .file-border:last-child {
    @apply border-b rounded-bl;
  }
}

@variants group-hover {
  .file-icon {
    transform: scale(1.15);
    transition-duration: 0.25s;
    transition-timing-function: ease;
  }
}

.file-border:hover {
  @apply bg-teal-25;
}

.icon-shadow {
  filter: drop-shadow(2px 4px 3px rgb(0 0 0 / 20%));
}

.mobile-menu-border {
  @apply border-t border-indigo-600;
}

.mobile-menu-border:first-child {
  @apply border-none;
}

.portal-switcher {
  @apply appearance-none py-2 pl-4 pr-10 rounded-r rounded-l-none text-white bg-indigo-700 leading-normal;

  background-image: url("../../assets/images/icons/select-arrow.svg");
  color-adjust: exact;
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

.portal-file-group .icon {
  transition: 0.3s;
}

.portal-file-group .open .icon {
  transform: rotate(90deg);
}

.header-height {
  height: 68px;
}

.portal-screen {
  height: calc(100vh - 73px); /* Magic number is header height minus some padding */
}

.bg-purple-hexagons {
  background-image: url("../../assets/images/hexagons.png");
}

/* Mobile case to ensure no thin charts */
.fixed-chart-rows-1,
.fixed-chart-rows-2,
.fixed-chart-rows-3,
.fixed-chart-rows-4,
.fixed-chart-rows-5,
.fixed-chart-rows-6 {
  min-height: 18rem;
}

@screen sm {
  .horizontally-fixed-bar {
    position: sticky;
    left: 0;
    right: 0;
    max-width: 100vw;
  }

  .fixed-chart-cols-1 {
    min-width: 18rem;
  }

  .fixed-chart-cols-2 {
    min-width: 36rem;
  }

  .fixed-chart-cols-3 {
    min-width: 54rem;
  }

  .fixed-chart-cols-4 {
    min-width: 72rem;
  }

  .fixed-chart-cols-5 {
    min-width: 90rem;
  }

  .fixed-chart-cols-6 {
    min-width: 108rem;
  }

  .fixed-chart-rows-2 {
    min-height: 36rem;
  }

  .fixed-chart-rows-3 {
    min-height: 54rem;
  }

  .fixed-chart-rows-4 {
    min-height: 72rem;
  }

  .fixed-chart-rows-5 {
    min-height: 90rem;
  }

  .fixed-chart-rows-6 {
    min-height: 108rem;
  }
}

.coverage-table-grid {
  @apply grid;

  grid-template-columns: 1fr 1fr;
}

@screen xs {
  .coverage-table-grid {
    width: min(78rem, calc(100vw - 3rem));
  }
}

@screen sm {
  .coverage-table-grid {
    grid-template-columns: 3fr minmax(max-content, 1fr) 2fr;
  }
}

@font-face {
  font-family: SymbolaRegular;
  src: url('../../assets/fonts/Symbola.ttf') format('truetype');
}
