html {
  line-height: 1.15;
}

body {
  @apply bg-gray-50 font-sans text-gray-700;
}

template {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-condensed;
}

h1 {
  @apply text-3xl font-bold;
}

h2 {
  @apply text-2xl font-bold;
}

h3 {
  @apply text-xl font-bold;
}

h4 {
  @apply text-lg font-bold;
}

.shift {
  margin-left: 12rem;
}

svg {
  @apply inline;

  vertical-align: baseline;
}

hr {
  @apply h-px bg-gray-200 my-6;
}

p {
  @apply mb-4 break-words;
}

p:last-child {
  @apply mb-0;
}

p a {
  @apply text-purple-900;
}

label,
.label {
  @apply block text-gray-700 text-xl font-semibold mb-2 font-condensed;
}

.break-out-of-label {
  @apply text-base font-normal font-sans mb-0;
}

form label,
form .label {
  @apply ml-3;
}

.label-small {
  @apply block text-gray-700 text-base font-semibold mb-2 font-condensed;
}

.required::after {
  content: " *";
}

button,
input,
optgroup,
select,
textarea {
  @apply leading-tight;
}

input[type="text"]:not(.choices__input, .form-input, .form-select),
input[type="number"],
input[type="password"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="time"] {
  @apply appearance-none border-b border-gray-800 w-full py-2 px-3 text-gray-400 h-10;
}

input[type="text"].text-sm:not(.choices__input, .form-input, .form-select),
input[type="number"].text-sm,
input[type="password"].text-sm,
input[type="url"].text-sm,
input[type="email"].text-sm,
input[type="tel"].text-sm,
input[type="time"].text-sm {
  @apply py-1 px-2 h-8 leading-4;
}

.no-border-at-rest:not(:focus, :hover) {
  @apply border-transparent;
}

input[type="text"]:not(.choices__input, .form-input, .form-select):focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="url"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus {
  @apply outline-none border-teal bg-gray-100 text-gray-700;
}

input[type="number"].no-spin-buttons {
  appearance: textfield;
}

input[type="number"].no-spin-buttons::-webkit-inner-spin-button,
input[type="number"].no-spin-buttons::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

textarea {
  @apply appearance-none border-b border-gray-800 w-full py-3 px-3 text-gray text-base;
}

textarea:focus {
  @apply outline-none border-teal bg-gray-100 text-gray-700;
}

input::placeholder,
textarea::placeholder {
  color: inherit;
  opacity: 0.5;
}

.input-group {
  @apply mb-8;
}

.field-with-errors input[type="text"]:not(.choices__input),
.field-with-errors textarea,
.field-with-errors input[type="password"],
.field-with-errors input[type="url"],
.field-with-errors input[type="email"],
.field-with-errors input[type="tel"],
.field-with-errors input[type="time"] {
  @apply border-red text-red;
}

.error-message {
  @apply text-red text-sm px-3 py-2 font-bold;
}

.btn {
  @apply animate-slow bg-purple text-white text-sm py-2 px-4 rounded cursor-pointer font-semibold leading-tight tracking-wide uppercase border border-purple inline-block;
}

.btn:focus {
  @apply outline-none shadow-outline;
}

.btn:hover {
  @apply bg-purple-300 border-purple-300;
}

.btn:disabled {
  @apply bg-gray-200 border-gray-200 cursor-not-allowed;
}

.btn-secondary {
  @apply btn;
  @apply text-gray bg-transparent border-transparent border border-gray font-normal;
}

.btn-teal {
  @apply btn bg-teal border-teal;
}

.btn-teal:hover:not(:disabled) {
  @apply bg-teal-500 border-teal-500;
}

.btn-secondary:hover:not(:disabled) {
  @apply text-teal shadow-md bg-white border-teal;
}

.group:hover .group-hover\:btn-secondary {
  @apply text-teal shadow-md bg-white border-teal;
}

.btn-destructive {
  @apply btn-secondary;
}

.btn-destructive:hover:not(:disabled) {
  @apply text-red border-red shadow-md bg-white;
}

.btn-red {
  @apply btn bg-red-400 border-red-400 text-white;
}

.btn-red:hover {
  @apply bg-red-500 border-red-500;
}

.btn-small {
  @apply text-sm py-1 px-2;
}

.btn-small-wide {
  @apply text-sm py-1 px-4;
}

.btn-xs {
  @apply text-xs py-1 px-2;
}

.btn-xs-wide {
  @apply text-xs py-1 px-4;
}

.link {
  @apply text-gray cursor-pointer;
}

.link:hover {
  @apply text-teal;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.empty-state {
  @apply ml-4 text-gray-300;
}

.list-item {
  @apply flex items-center justify-between p-4 rounded;
}

.list-item:hover {
  @apply bg-teal-25;
}

.row-button {
  @apply text-gray p-2;
}

.row-button:hover {
  @apply text-teal;
}

.description {
  @apply pr-4 text-right text-xs uppercase font-condensed tracking-md;
}

.comparison-column {
  @apply flex flex-col border-l pb-4;
}

.comparison-label {
  @apply flex items-center font-bold uppercase tracking-md text-xs py-6 px-4 text-right h-12 justify-end;
}

.comparison-value {
  @apply flex items-center py-6 h-12 justify-center;
}

.comparison-title {
  @apply flex items-center h-12 border-b font-semibold justify-center;
}

.comparison-column.active {
  @apply bg-purple-50;
}

.comparison-column.active .comparison-title {
  @apply border-b-2 border-purple text-purple;
}

.responsive-column,
.responsive-column-third,
.responsive-column-two-third,
.responsive-full {
  @apply w-full;
}

.responsive-wrapper {
  @apply flex-wrap;
}

@screen lg {
  .responsive-wrapper {
    @apply flex;
  }

  .responsive-full {
    @apply w-full px-4;
  }

  .responsive-column {
    @apply w-half px-4;
  }

  .responsive-column-third {
    @apply w-third px-4;
  }

  .responsive-column-two-third {
    @apply w-two-third px-4;
  }
}

.meeting-list-row {
  @apply rounded-lg;
}

tr .actions,
.card-row .actions,
.meeting-list-row .actions {
  @apply invisible;
}

.meeting-list-row:hover {
  @apply bg-teal-25;
}

tr:hover .actions,
.card-row:hover .actions,
.meeting-list-row:hover .actions {
  @apply visible;
}

.overflow-actions {
  @apply w-32 absolute bg-white shadow-lg text-center z-50 my-4;
}

.honorarium-payment {
  @apply w-40 absolute bg-white shadow-lg z-50 p-4 my-4 rounded;
}

.overflow-action {
  @apply px-2 py-4 select-none;
}

.overflow-action:hover {
  @apply bg-teal-25;
}

.overflow-actions a,
.overflow-actions button {
  @apply w-full text-gray font-semibold uppercase;
}

.overflow-actions a:hover,
.overflow-actions button:hover {
  @apply text-teal;
}

.overflow-dots {
  fill: #888d8c;
}

svg.active .overflow-dots {
  fill: #fff;
}

.overflow-button:hover .overflow-dots {
  fill: #54b5c6;
}

.overflow-button:hover .active .overflow-dots {
  fill: #fff;
}

.overflow-button:hover .information {
  fill: #54b5c6;
}

.overflow-button:hover .active .information {
  fill: theme("colors.teal.default");
}

.medicaid-meeting-list-export-subitem {
  @apply p-0 m-0 font-sans font-bold uppercase;
}

.medicaid-meeting-list-export-subitem:hover {
  @apply bg-teal-25;
}

.collapsible-panel {
  @apply rounded-lg bg-white shadow;
}

.collapsible-panel .heading {
  @apply bg-white text-gray cursor-pointer rounded-lg;
}

.collapsible-panel .heading:hover {
  @apply bg-teal-25 text-teal;
}

.collapsible-panel .heading.open {
  @apply bg-teal text-white rounded-b-none;
}

.collapsible-panel .loading {
  @apply h-12 text-center text-xl text-gray-300 mt-8;
}

.collapsible-panel .heading .icon {
  @apply px-4 py-4 mr-2;

  transition: 0.3s;
}

.collapsible-panel .tracker-panel-heading .icon {
  @apply px-4 py-4;

  transition: 0.3s;
}

.collapsible-panel .tracker-panel-heading.open .icon {
  @apply text-white;

  transform: rotate(90deg);
}

.collapsible-panel .heading.open .icon {
  @apply text-white;

  transform: rotate(90deg);
}

.report-dropdown-link {
  @apply text-center block px-12 py-4 text-gray;
}

.report-dropdown-link:hover {
  @apply text-teal;
}

.report-card {
  @apply border border-gray-200 rounded mb-4 w-two-third;
}

.report-label {
  @apply font-bold text-xl mb-1;
}

.btn-secondary-report {
  @apply text-base py-2 px-4 mb-4 rounded cursor-pointer border inline-block text-gray-700 bg-transparent border-transparent border border-gray;

  width: calc(50% - theme('margin.4'));
}

.btn-secondary-report:nth-child(odd) {
  @apply mr-2;
}

.btn-secondary-report:nth-child(even) {
  @apply ml-2;
}

.btn-secondary-report:hover {
  @apply text-teal shadow-md bg-white border-teal;
}

.btn-flat {
  @apply flex items-center p-2 text-gray rounded-full;
}

.btn-flat:hover {
  @apply text-teal bg-teal-25;
}

.btn-flat:focus {
  @apply outline-none shadow-outline;
}

.btn-flat-red:hover {
  @apply text-red bg-red-50;
}

.btn-white {
  @apply rounded py-2 px-3 mx-2 cursor-pointer flex items-center outline-none select-none;
}

.btn-white:hover {
  @apply bg-teal-25 text-teal;
}

.btn-white:focus {
  @apply outline-none shadow-outline;
}

.btn-green {
  @apply rounded-full py-2 px-3 cursor-pointer flex items-center bg-green-400 text-white;
}

.btn-green:hover {
  @apply bg-green-50 text-teal-700;
}

.dropdown-btn {
  @apply inline-block;
}

.dropdown-btn:hover {
  @apply text-teal border-teal;
}

.dropdown-btn .active {
  @apply text-teal border-teal;
}

.dropdown-arrow {
  @apply inline-block border-l border-gray p-3;
}

.dropdown-btn .active > .dropdown-arrow {
  @apply bg-teal-25;
}

.dropdown-btn:hover > .dropdown-arrow {
  @apply border-teal;
}

.dropdown-menu {
  margin-top: 2.9rem;

  @apply shadow absolute top-0 left-0 bg-white;
}

.dropdown-content {
  @apply m-3 inline-block;
}

.report-dropdown-container {
  @apply relative mr-4 inline-block;
}

.report-dropdown-interact-btn {
  @apply p-0 m-0 font-sans uppercase border border-gray rounded cursor-pointer leading-tight tracking-wide inline-block;
}

.tab {
  @apply text-gray bg-gray-100 cursor-pointer text-2xl text-center py-4 px-8;
}

.tab.active {
  @apply text-teal bg-white;
}

.tab-content {
  @apply hidden w-full;
}

.tab-content.active {
  @apply block;
}

.content-header {
  @apply bg-white border-b py-3 px-6;
}

.secondary-content-header {
  @apply content-header py-0 bg-gray-50;
}

.content-wrapper {
  @apply relative;
}

.content-wrapper::after {
  /* omitting top-0 and left-0 allows the element to use its static position while staying fixed */
  @apply fixed pointer-events-none w-full h-full z-0;

  box-shadow: inset 8px 8px 6px -6px rgb(0 0 0 / 12%);
  content: '';
}

.content {
  @apply mx-auto min-w-full;
}

@screen lg {
  .content {
    @apply mx-auto min-w-screen;
  }
}

.header-tabs {
  @apply flex -mb-3 mt-2;
}

.header-tab {
  @apply font-condensed text-sm text-gray tracking-md mr-2 py-2 px-3;
}

.header-tab:hover {
  @apply text-teal bg-teal-25 rounded-t;
}

.header-tab.active {
  @apply text-teal font-semibold border-b-2 border-teal;
}

.secondary-header-tab {
  @apply header-tab text-xs;
}

.secondary-header-tab:hover {
  @apply text-purple bg-purple-50 rounded-t;
}

.secondary-header-tab.active {
  @apply text-purple font-semibold border-b-2 border-purple;
}

.tertiary-header-tab {
  @apply header-tab text-xs;
}

.tertiary-header-tab:hover {
  @apply text-indigo-400 bg-indigo-100 rounded-t;
}

.tertiary-header-tab.active {
  @apply text-indigo-400 font-semibold border-b-2 border-indigo-400;
}

.last-updated-header {
  @apply text-right text-xs text-gray tracking-sm;
}

.breadcrumbs {
  @apply flex items-center px-6 truncate;
}

.breadcrumb {
  @apply text-gray-700 text-sm select-none truncate;
}

.breadcrumb-link {
  @apply text-gray ;
}

.breadcrumb-link:hover {
  @apply text-teal;
}

.breadcrumb-separator {
  @apply text-gray-200 px-2;
}

.letter-link {
  @apply p-2 mr-1 text-gray uppercase rounded;
}

.letter-link:hover {
  @apply bg-purple-50 text-purple;
}

.letter-link.active {
  @apply bg-purple-50 text-purple font-semibold;
}

.left-nav-header-tab {
  @apply uppercase text-center rounded pt-3 pb-2;
}

.left-nav-header-tab:not(.active) {
  @apply bg-gray-50 text-gray border-b;
}

.left-nav-header-tab:hover {
  @apply bg-purple-50 text-purple;
}

.left-nav-header-tab.active {
  @apply text-purple font-semibold border-0;
}

.empty-cell::before {
  @apply text-gray-300;

  /* em dash */
  content: "–";
}

.table-link {
  @apply text-gray-700 font-semibold;
}

.table-link:hover {
  @apply text-teal;
}

.table-row {
  @apply h-16;
}

.table-row-sm {
  @apply h-10;
}

.table-row td:first-child,
.table-row-sm td:first-child {
  @apply rounded-l-lg;
}

.table-row td:first-child,
.table-row-sm td:last-child {
  @apply rounded-r-lg;
}

.table-row:hover,
.table-row-sm:hover {
  @apply bg-teal-25;
}

.comment-dump p br {
  @apply py-4;
}

.checkbox-label {
  @apply font-normal font-sans text-base inline-block m-0 pb-0 px-2;
}

.form-checkbox-label {
  @apply text-lg leading-none flex items-center font-normal;
}

.form-checkbox-label .form-checkbox,
.form-checkbox-label .form-radio {
  @apply mr-3;
}

.meeting-location > p {
  margin: 0;
}

.disabled-field {
  @apply opacity-50 select-none cursor-not-allowed;
}

.forgot-password::before {
  content: "(";
}

.forgot-password::after {
  content: ")";
}

.match-selectize-border-height {
  padding-bottom: 6px;
}

.row-separator {
  @apply border-b pb-2 mb-2;
}

.row-separator:last-child {
  @apply border-0 pb-0 mb-auto;
}

.hover\:cursor-zoom:hover {
  cursor: zoom-in;
}

.attachment-card .attachments-border {
  @apply border-b;
}

.attachment-card:last-child .attachments-border {
  @apply border-none;
}

.object-contain {
  object-fit: contain;
}

.sidebar-section {
  @apply flex justify-between items-center h-8 cursor-pointer pl-2;
}

.sidebar-section:hover {
  @apply bg-teal-25;
}

.sidebar-item {
  @apply text-gray-700 font-semibold ml-4;
}

.divider-heading {
  @apply w-full my-6 flex items-center text-gray-600;
}

.divider-heading::before,
.divider-heading::after {
  @apply border-b-2 border-gray-200 flex-1 mt-1;

  content: '';
}

.divider-heading::before {
  @apply mr-3;
}

.divider-heading::after {
  @apply ml-3;
}

.group-header {
  @apply h-8 flex items-center text-base not-italic bg-gray-100 pl-4 border-b border-gray-200 text-gray-400;
}

.group-header-pinned {
  @apply group-header bg-yellow-50 border-yellow-100 font-bold text-yellow-700;
}

.group-header-icon {
  @apply h-4 text-gray mr-4;
}

.empty-list {
  @apply flex flex-col items-center py-2 px-6 text-center max-w-350 mx-auto self-center;
}

.empty-list-full-page {
  @apply my-48;
}

.empty-list-icon {
  @apply h-12 mb-4 text-gray-300;
}

.empty-list-message {
  @apply text-lg text-gray-400;
}

.empty-list-message:not(:last-child) {
  @apply mb-4;
}

.empty-list-description {
  @apply text-gray-300 -mt-2;
}

.hide-if-empty:empty {
  @apply hidden;
}

.place-center {
  place-items: center;
}

.bg-pdf {
  background-color: #d82219;
}

.bg-word {
  background-color: #285293;
}

.bg-powerpoint {
  background-color: #ed6c00;
}

.break-inside {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.break-anywhere {
  @apply break-words;

  overflow-wrap: anywhere;
}

/* Shadows classes from newer versions of TailwindCSS */
.grid-rows-subgrid {
  grid-template-rows: subgrid;
}

.grid-columns-subgrid {
  grid-template-columns: subgrid;
}

@responsive {
  .columns-1 {
    column-count: 1;
  }

  .columns-2 {
    column-count: 2;
  }

  .columns-3 {
    column-count: 3;
  }

  .columns-4 {
    column-count: 4;
  }
}

.columns-2 > *,
.columns-3 > *,
.columns-4 > * {
  @apply break-inside;
}

#bullet-footer summary {
  padding: 3px 10px !important;
}

@screen lg {
  #bullet-footer {
    @apply max-w-350;

    left: 200px !important;
  }
}

.multiline-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

mark {
  /* Update known_outlook_incompatibilities_with_equivalents in trix_helper.rb when changing this */
  @apply bg-yellow-100;
}

input:checked + * .label-checked\:border-black {
  @apply border-black;
}

[data-tooltip-arrow]::after,
.tooltip-arrow-down::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 0.75rem;
  border-width: 0.5rem;
  border-color: white transparent transparent;
}

.tooltip-arrow-up::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 0.75rem;
  border-width: 0.5rem;
  border-color: transparent transparent white;
}

.tooltip-arrow-up-center::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: calc(50% - 0.5rem);
  border-width: 0.5rem;
  border-color: transparent transparent white;
}

.floating-header-scroll-offset {
  scroll-margin-top: 200px;
}

.master-entity-picker-entry {
  @apply flex items-stretch cursor-pointer px-3;
}

.master-entity-picker-entry:hover {
  @apply bg-teal-25 text-teal;
}

.chart-type-grid .chart {
  border: 3px solid transparent;
  transition: border-color 0.1s;
}

.chart-type-grid .chart:hover {
  @apply border-blue-200;
}

.chart-type-grid input:checked + .chart {
  @apply border-blue-400;
}

.portal-analytics-widget-grid .widget.active {
  @apply z-20;
}

.highlight-changed {
  background-color: #d0fafa;
}

.change-indicator {
  color:#0694A2;
}

@screen short-md {
  .short-flyout-menu {
    @apply absolute invisible overflow-hidden w-max-content;

    transition: visibility 0ms 120ms; /* prevent accidantal "unhovers" */
    min-width: 150px;
    left: 100%;
    top: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .short-flyout-menu-container:hover > .short-flyout-menu {
    @apply visible;

    transition-duration: 80ms; /* appearing delay was too noticeable */
    z-index: 1;
  }
}

.scroll-margin-24 {
  scroll-margin: 6rem;
}

/* This will apply the elipsses of .truncate to the *beginning* of the string, preserving the end.
   E.g. "Very long text" will truncate to "... long text", instead of "Very long ..."
   It is unfortunately not compatible with any of the .max-lines-n styles */
.truncate-start {
  @apply truncate;

  direction: rtl;
}

/* -webkit-line-clamp and its etceterae are TECHNICALLY just draft proposals and not
   standardized, but they're supported by every browser but IE on desktop and mobile.

   line-height is not directly necessary, but since we define a global line-height: 1.15,
   Firefox cuts off the bit of text underneith the baseline (e.g. the tail of a j or g).
   This is true of ALL overflow: hidden elements, not just line-clamped ones.

   Note: <p> elements do not play nicely with -webkit-line-clamp!  <br> tags, however, do.
   If formatting newline-separated text with pretty_format(...), use pretty_format_inline instead.

   Safari, meanwhile, is its own set of problems.  While -webkit-line-clamp works fine for flat HTML,
   any kind of nontrivial structure breaks it completely, and not in consistent ways.
   Sometimes content will be rendered in full, sometimes it won't render at all, sometimes it renders on top of itself.
   Thus, regress to a more primative implementation that exploits a marginless line of text's height as exactly line-height. */

html.browser-safari .max-lines-2,
html.browser-ios .max-lines-2 {
  max-height: 2.3rem;
  overflow: hidden;
}

html.browser-safari .max-lines-4,
html.browser-ios .max-lines-4 {
  max-height: 4.6rem;
  overflow: hidden;
}

html.browser-safari .max-lines-16,
html.browser-ios .max-lines-16 {
  max-height: 18.4rem;
  overflow: hidden;
}

html.browser-safari .max-lines-2 *,
html.browser-safari .max-lines-4 *,
html.browser-safari .max-lines-16 *,
html.browser-ios .max-lines-2 *,
html.browser-ios .max-lines-4 *,
html.browser-ios .max-lines-16 * {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  line-height: 1.15 !important;
}

html:not(.browser-safari, .browser-ios) .max-lines-2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: normal;
}

html:not(.browser-safari, .browser-ios) .max-lines-4 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: normal;
}

html:not(.browser-safari, .browser-ios) .max-lines-16 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 16;
  line-height: normal;
}

.-z-10 {
  z-index: -10
}
