.modal {
  @apply fixed inset-0 w-full h-full m-auto flex flex-col justify-center items-center z-dialog;
}

.modal::before {
  @apply fixed top-0 left-0 w-full h-full bg-gray-300 opacity-50;

  content: '';
}

.modal-content-minimal {
  @apply bg-white z-10;

  max-height: 100%;
  overflow-y: auto;
}

.modal-content {
  @apply bg-white rounded shadow p-8 min-w-modal z-10;

  max-height: 100%;
  overflow-y: auto;
}

.modal-content.w-mobile-400 {
  min-width: unset;
  width: min(90vw, 400px);
}

.modal-content.w-mobile-600 {
  min-width: unset;
  width: min(90vw, 600px);
}

.modal-overflow-select-workaround .selectize-input {
  max-height: 10rem;
  overflow-y: auto;
}
