.side-nav-logo-container {
  @apply flex text-white items-center h-12 flex-none bg-indigo-nav-active;
}

.side-nav-logo {
  @apply block h-9 ml-3;
}

.side-nav {
  @apply flex-grow bg-indigo flex flex-col;
}

.side-nav-group {
  @apply bg-indigo border-b border-transparent relative z-100;
}

.side-nav-group.active {
  @apply bg-indigo-nav-active border-indigo-500;
}

.side-nav-group.active:first-child {
  border-top-color: transparent;
}

.side-nav-spacer {
  @apply flex-grow;
}

.side-nav-item {
  @apply w-full pl-3 pr-2 py-1 flex items-center font-semibold text-base border-l-4 border-transparent;

  color: #cbc9f0;
}

.top-nav-item-minimal,
.top-nav-item {
  @apply px-5 py-2 text-base text-white font-medium;
}

.top-nav-item {
  @apply border-b-4 border-transparent;
}

.side-nav-item:hover,
.top-nav-item:hover,
.top-nav-item-minimal:hover {
  @apply text-white bg-indigo-nav-active;
}

.side-nav-item.active,
.top-nav-item.active,
.top-nav-item-minimal.active {
  background-color: #706daf;
}

.side-nav-item.active,
.top-nav-item.active {
  @apply border-teal text-white font-semibold;
}

.side-nav-item-icon {
  @apply overflow-visible text-indigo-300 animate-slow;

  margin-left: 0.2rem;
  margin-right: 0.8rem;
  width: 1.125rem;
}

.side-nav-item-children-indicator {
  @apply w-2 ml-auto text-indigo-400;

  transform: rotate(-90deg);
}

.side-nav-item:hover .side-nav-item-icon,
.side-nav-item.active .side-nav-item-icon {
  @apply text-white;

  transform: scale(1.125);
}

.side-nav-group:hover {
  @apply bg-indigo-nav-active;
}

.side-nav-group.active .side-nav-item-children-indicator {
  @apply hidden;
}

.side-nav-item-sub {
  @apply text-sm pl-12 font-normal;
}

.side-nav-item-sub.active,
.side-nav-item-sub.active:hover {
  @apply border-teal;
}

.side-nav-group.active > .side-nav-item:first-child,
.side-nav-group.active .side-nav-item-icon {
  @apply text-white;
}

@screen short {
  .side-nav .side-nav-group.active,
  .side-nav .side-nav-group.active:hover {
    background-color: #706daf;
  }

  .side-nav .side-nav-group.active .side-nav-item-icon {
    @apply text-white;

    transform: scale(1.125);
  }

  .side-nav .side-nav-group.active > .side-nav-item {
    @apply border-teal text-white font-semibold;
  }

  .side-nav .side-nav-group.active .side-nav-item:first-child:hover {
    @apply bg-transparent;
  }
}

/* begin flyout menus */

.side-nav-group:not(.active) > .side-nav-item-sub-group {
  @apply absolute invisible overflow-hidden w-max-content;

  transition: visibility 0ms 120ms; /* prevent accidantal "unhovers" */
  min-width: 150px;
  background-color: #4f4c90;
  left: 100%;
  top: -1px; /* to accommodate the border */
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.side-nav-group:not(.active):hover > .side-nav-item-sub-group {
  @apply visible;

  transition-duration: 80ms; /* appearing delay was too noticeable */
  z-index: 1;
}

.side-nav-group:not(.active):hover > .side-nav-item-sub-group > :first-child {
  @apply pt-2;
}

.side-nav-group:not(.active):hover > .side-nav-item-sub-group > :last-child {
  @apply pb-2;
}

.side-nav-group:not(.active) .side-nav-item-sub {
  @apply px-2 animate-slow;
}

.side-nav-group:not(.active, :hover) .side-nav-item-sub {
  transition-delay: 150ms;
  transform: translateX(-0.5rem);
}

@screen short {
  .side-nav .side-nav-group.active .side-nav-item-children-indicator {
    @apply block text-indigo-100;
  }

  .side-nav .side-nav-group > .side-nav-item-sub-group {
    @apply absolute invisible overflow-hidden w-max-content;

    transition: visibility 0ms 120ms; /* prevent accidantal "unhovers" */
    min-width: 150px;
    background-color: #4f4c90;
    left: 100%;
    top: -1px; /* to accommodate the border */
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .side-nav .side-nav-group:hover > .side-nav-item-sub-group {
    @apply visible;

    transition-duration: 80ms; /* appearing delay was too noticeable */
    z-index: 1;
  }

  .side-nav .side-nav-group:hover > .side-nav-item-sub-group > :first-child {
    @apply pt-2;
  }

  .side-nav .side-nav-group:hover > .side-nav-item-sub-group > :last-child {
    @apply pb-2;
  }

  .side-nav .side-nav-group .side-nav-item-sub {
    @apply px-2 animate-slow;
  }

  .side-nav .side-nav-group:not(:hover) .side-nav-item-sub {
    transition-delay: 150ms;
    transform: translateX(-0.5rem);
  }

  .side-nav .side-nav-item-sub.active,
  .side-nav .side-nav-item-sub.active:hover {
    @apply border-transparent;
  }
}

/* end flyout menus */
