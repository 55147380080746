.select-popup {
  @apply bg-white rounded-lg shadow-lg z-dialog overflow-hidden;
}

.select-popup-header {
  @apply flex items-center justify-between text-sm bg-gray-50 border-b px-3 py-1 text-gray-600 font-semibold;
}

.select-popup-list {
  @apply p-3 max-h-300 overflow-auto;
}

.select-popup-action-footer {
  @apply bg-indigo-500 text-white text-center text-base;
}

.select-popup-action-footer:hover {
  @apply bg-indigo-600;
}

.horizontal-priority-sortable {
  @apply relative;
}

.horizontal-priority-sortable:not(:last-of-type) {
  @apply mr-4;
}

/* Sortablejs identifies an actively clicked element with 'sortable-chosen',
   and once a drag begins it identifies the "placeholder" with 'sortable-ghost'.
   HTML 5 drag-and-drop will mirror and use the element at the state in between:
   what you see as your cursor while dragging is .sortable-chosen:not(.sortable-ghost).
   Therefore, in order to not have a > shown during the drag, we must exclude in that case. */
.horizontal-priority-sortable:not(:last-of-type, .sortable-chosen)::after,
.horizontal-priority-sortable:not(:last-of-type).sortable-chosen.sortable-ghost::after {
  content: ">";
  position: absolute;
  right: -1rem;
  top: 0.2rem;
}

/* However, we do actually want to see a > in that case, just not under the cursor during the drag!
   Therefore, in order to not be picked up by HTML5 drag-and-drop, it must originate from a DIFFERENT element. */
.horizontal-priority-sortable.sortable-chosen:not(.sortable-ghost) + .horizontal-priority-sortable::before {
  content: ">";
  position: absolute;
  left: -1rem;
  top: 0.2rem;
}
