.application-card {
  @apply bg-white rounded-lg shadow p-8 my-4;
}

.card {
  @apply bg-white rounded-lg shadow p-8 mb-4;
}

.card-component {
  @apply bg-white rounded shadow my-4 border-indigo-500 border-t-8;
}

.clickable-card-component {
  @apply card-component transition-smooth;
}

.clickable-card-component:hover {
  @apply shadow-lg;
}

.stretch-vertically {
  @apply flex flex-col items-stretch h-full;
}

.card-title-section {
  @apply flex justify-between items-center px-6 py-3 border-b border-gray-200 min-h-12;
}

.card-title {
  @apply text-indigo-600 font-semibold text-xl tracking-sm;
}

.card-section {
  @apply px-6 py-6;
}

.card-section-sm {
  @apply px-6 py-4;
}

.card-section-multi {
  @apply px-6 py-3;
}

.card-section-multi:first-child {
  @apply pt-6;
}

.card-section-multi:last-child {
  @apply pb-6;
}

.card-instructions {
  @apply px-6 py-4 border-b bg-indigo-50 text-sm text-indigo-600;
}

.card-warning {
  @apply px-6 py-4 border-b bg-yellow-50 text-sm text-yellow-600;
}

.card-list-section {
  @apply flex-col;
}

.card-row {
  @apply flex justify-between items-start mb-6;
}

.card-actions {
  @apply card-section-sm border-t bg-gray-50 text-gray flex justify-between items-center rounded-b;
}

.card-footer {
  @apply border-t bg-gray-50 text-gray text-sm flex justify-between items-center rounded-b;
}

.card-data {
  @apply ml-4;
}
